import React, { useContext, useState, useEffect } from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import './footer.css';
import { UserContext } from '../../Utills/UserContext';
import { useNavigate, useLocation } from "react-router-dom";
import FetchData from '../../fetchapi/Apifetch';
import Visitor from './visitor';

const Footer = () => {
  const { theme } = useContext(UserContext); // Get the theme from context
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const navigate = useNavigate();
  const [visitors, setVisitors] = useState('');
  const location = useLocation();
  const listvisitors = () => {
    const storedVisitors = localStorage.getItem("visitors");
    if (storedVisitors) {
      setVisitors(JSON.parse(storedVisitors));
    }
  };
  const Storedvalue = JSON.parse(localStorage.getItem("email"));
  const profiledetails = Storedvalue?.user;
  const fetchAndStoreVisitors = async () => {
    const res = await FetchData(
      `getvisitors`,
      "GET",
      null,
      true,
      false
    );

    if (res) {
      const totalVisitors = res?.data?.totalVisitors || 0;
      setVisitors(totalVisitors);
      localStorage.setItem("visitors", JSON.stringify(totalVisitors));
    }
  };

  useEffect(() => {
    listvisitors();
    fetchAndStoreVisitors();
    const intervalId = setInterval(fetchAndStoreVisitors, 180000);
    return () => clearInterval(intervalId);
  }, []);

  const textColor = theme ? '#fff' : '#000';
  const iconColor = theme ? '#fff' : '#000';
  const hoverColor = '#ffcb05';

  return (
    <div className="footer-container mt-4 m-auto"
      style={{
        backgroundColor: theme ? "#171717" : "#fff",
        boxShadow: "0px 0px 4px 0px #00000040",
      }}
    >
      <div className='w-100 d-flex justify-content-center m-auto '>
        <div className="d-flex row justify-content-between flex-wrap mt-2  container">
          <div className="footer-right col-lg-3 col-sm-12  col-md-5 ps-0 " >
            <div className='mb-3'>
              <a href="/" className='d-flex' >
                <img src={"logonew.png"} className='me-2' width={35} height={35} alt="Logo" />
                <img src={theme ? "images/app/itg-logo1.png" : "images/app/itg-logo-light1.png"} className='app-logo1' alt="Logo" />
              </a>
            </div>
            <p className="footer-subtitle ps-1" style={{ color: textColor, fontWeight: '400', fontSize: '14px' }}>
              Empowering Ethical Trade Worldwide
            </p>
            <p className={`${theme ? 'footer-links-title' : 'footer-links-title-light'} text-start`} style={{ fontWeight: '500' }}>     Address </p>
            <ul className={`${theme ? "footer-links" : 'footer-links'} text-start`}>
              <li className='mb-2' style={{ color: textColor, fontWeight: '400', fontSize: '14px' }}>
                Shop No.2, Pearl Oasis Apartment,
              </li>
              <li className='mb-2' style={{ color: textColor, fontWeight: '400', fontSize: '14px' }}>
                Pathanwadi, Malad East,
              </li>
              <li style={{ color: textColor, fontWeight: '400', fontSize: '14px' }}>
                Mumbai - 400097
              </li>
            </ul>
          </div>
          <div className="footer-right text-center col-lg-2 col-sm-6 col-md-3" style={{ marginTop: isMobile ? "3%" : "" }}>
            <p className={`${theme ? 'footer-links-title' : 'footer-links-title-light'} text-start`}>Quick Links</p>
            <div className={`${theme ? "footer-links-dark" : 'footer-links'} text-start `} >
              <div className='mb-4 ms-1'>
                <a
                  className="pointer"
                  href={`${profiledetails?.first_name ? "/dashboard" : "/login"}`}  // This will act as the link
                  style={{ color: textColor }}
                >
                  {/* <img src='/images/icons/arrow-dark.png' width={50} /> */}
                  <div className="d-flex" >
                    <img src={`${theme ? "images/icons/right-arrow-dark.png" : "images/icons/right-arrow-light.png"}`} className='footer-icone-nav'  alt='arrow' />
                    <span className='ms-2 font-footer'>Home</span></div>
                </a>
              </div>
              <div className='mb-4 ms-1'>
                <a
                  href={`${profiledetails?.first_name ? "/screener" : "/login"}`}
                  className="pointer"
                  style={{ color: textColor }}
                >
                  <div className="d-flex">
                    <img src={`${theme ? "images/icons/right-arrow-dark.png" : "images/icons/right-arrow-light.png"}`} alt='arrow' className='footer-icone-nav'   />
                    <span className='ms-2 font-footer'>Screener</span></div>
                </a>
              </div>
              <div className='mb-4 ms-1'>
                <a

                  href={`${profiledetails?.first_name ? "/watchlist" : "/login"}`}
                  style={{ color: textColor }}
                  className='text-start pointer'

                >
                  <div className="d-flex" >
                    <img src={`${theme ? "images/icons/right-arrow-dark.png" : "images/icons/right-arrow-light.png"}`} alt='arrow' className='footer-icone-nav'   />
                    <span className='ms-2 font-footer'>Watchlist</span>
                  </div>
                </a>
              </div>
              {/* <div className='mb-4 ms-1'>
                <a
                  className='text-start pointer'
                  href={`${profiledetails?.first_name ? "/premium" : "/login"}`}

                  style={{ color: textColor }}
                >
                  <div className='d-flex'>
                    <img src={`${theme ? "images/icons/right-arrow-dark.png" : "images/icons/right-arrow-light.png"}`} alt='arrow' className='footer-icone-nav'  >

                    </img>
                    <span className='ms-2 font-footer'>Premium</span>
                  </div>
                </a>
              </div> */}
              <div className='mb-4 ms-1'>
                <a href={`${profiledetails?.first_name ? "/contact-us" : "/login"}`} className='text-start pointer'
                  style={{ color: textColor, fontWeight: '400' }} >
                  <div className="d-flex">
                    <img src={`${theme ? "images/icons/right-arrow-dark.png" : "images/icons/right-arrow-light.png"}`} alt='arrow' className='footer-icone-nav'  />
                    <span className='ms-2 font-footer' >Contact Us</span></div>
                </a>
              </div>
            </div>
          </div>

          <div className="footer-right text-center col-lg-2 col-sm-6 col-md-3" style={{ marginTop: isMobile ? "3%" : "" }}>
            <p className={`${theme ? 'footer-links-title' : 'footer-links-title-light'} text-start`}>Company Links</p>
            <div className={`${theme ? "footer-links-dark" : 'footer-links'} text-start `} >
              <div className='mb-4 ms-1'>
                <a
                  href='/privacy-policy'
                  className='text-start pointer'
                  target='blank'

                  style={{ color: textColor }}
                >
                  <div className="d-flex">
                    <img src={`${theme ? "images/icons/right-arrow-dark.png" : "images/icons/right-arrow-light.png"}`} alt='arrow' className='footer-icone-nav'  />
                    <span className='ms-2 font-footer'>Privacy Policy</span></div>

                </a>
              </div>
              <div className='mb-4 ms-1'>
                <a
                  href={`${profiledetails?.first_name ? "/refund-policy" : "/login"}`}
                  className="pointer"
                  style={{ color: textColor }}
                >
                  <div className="d-flex">
                    <img src={`${theme ? "images/icons/right-arrow-dark.png" : "images/icons/right-arrow-light.png"}`} alt='arrow' className='footer-icone-nav'   />
                    <span className='ms-2 font-footer' >Refund Policy</span></div>
                </a>
              </div>
              <div className='mb-4 ms-1'>
                <a
                  className="pointer"
                  href={`${profiledetails?.first_name ? "/terms_conditions" : "/login"}`}
                  style={{ color: textColor }}
                >
                  <div className="d-flex">
                    <img src={`${theme ? "images/icons/right-arrow-dark.png" : "images/icons/right-arrow-light.png"}`} alt='arrow' className='footer-icone-nav'   />
                    <span className='ms-2 font-footer '>Terms & Conditions</span></div>
                </a>
              </div>
            </div>
            <div className={`p-4 ms-3 d-flex align-items-center ${!theme ? "newsemail-dark" : "newsemail"}`}>
              <span
                style={{
                  color: theme ? "#fff" : "#000",
                  fontWeight: "600",
                  fontSize: isMobile ? "16px" : "30px",
                  fontFamily: "cursive"
                }}
              >
                <span className='ms-1'>{visitors ? visitors : '0'}</span>
              </span>
            </div>
          </div>

          <div className="footer-right  col-lg-3 col-sm-6 align-items-left">
            <p className={`${theme ? 'footer-links-title' : 'footer-links-title-light'} text-start`}>Downloads</p>
            <a href='https://play.google.com/store/apps/details?id=com.qci.itg&pli=1' target='_blank'>

              <div className={`padding-app d-flex align-items-center pointer ${!theme ? "newsemail-dark" : "downloads"}`} style={{ width: "170px" }}>
                <img src='/images/islamic/google-play.png' className="me-2" width={25} height={25} />
                <div className="d-flex flex-column">
                  <span style={{ color: "#000", fontWeight: "400", fontSize: "10px" }}>
                    Android app on
                  </span>
                  <span style={{ color: "#000", fontWeight: "600", fontSize: isMobile ? "12px" : "14px" }}>
                    Google Play
                  </span>
                </div>
              </div>
            </a>

            <div className={`d-flex  align-items-center mt-2 ${!theme ? "newsemail-dark" : "downloads"}`} style={{ width: "170px" }}>
              <img src='/images/islamic/apple-logo.png' className="me-2" width={25} height={25} />
              <div className="d-flex flex-column">
                <span style={{ color: "#000", fontWeight: "400", fontSize: "10px" }}>
                  Download on the
                </span>
                <span style={{ color: "#000", fontWeight: "600", fontSize: isMobile ? "12px" : "14px" }}>
                  App Store
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Copyright Section */}
      <div className='' style={{ backgroundColor: theme ? '#7E66BC' : "#5b298c" }}>

        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center text-center p-2 container" >
          {/* Copyright text */}

          <p className={`mb-0 ms-0 ${theme ? 'footer-copyright-dark' : 'footer-copyright'}`} style={{ color: '#fff', fontWeight: '400', marginLeft: "30px" }}>
            © 2025 Islamic Tijarat. All Rights Reserved.
          </p>

          {/* Social Media Icons */}
          <div className={`${theme ? "social-icons-dark" : "social-icons"} mt-3 me-2 mt-md-0 d-flex justify-content-center`} >
            <a href="https://www.facebook.com/islamictijarat" target="_blank" rel="noopener noreferrer">
              <img src='images/facebook.webp' width={25} height={25} alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/islamictijarat" target="_blank" rel="noopener noreferrer">
              <img src='images/insta.webp' width={30} height={30} alt="Instagram" />
            </a>
            <a href="https://x.com/islamictijarat" target="_blank" rel="noopener noreferrer">
              <img src='images/twitter.webp' width={25} height={25} alt="Twitter" />
            </a>
            <a href="https://www.youtube.com/islamictijarat" target="_blank" rel="noopener noreferrer">
              <img src='images/youtube.webp' width={25} height={25} alt="YouTube" />
            </a>
            <a href="https://www.linkedin.com/in/islamictijarat" target="_blank" rel="noopener noreferrer">
              <img src='images/linkedin.webp' width={26} height={26} alt="LinkedIn" />
            </a>
            <a href="https://whatsapp.com/channel/0029VakHKaFJENy4EzxloA13" target="_blank" rel="noopener noreferrer">
              <img src='images/whatsapp.webp' width={25} height={25} alt="WhatsApp" />
            </a>
            <a href="https://t.me/islamictijarat" target="_blank" rel="noopener noreferrer">
              <img src='images/islamic/telegram.webp' width={25} height={25} alt="telegram" />
            </a>
          </div>

        </div>

      </div>
    </div>
  );
};
export default Footer