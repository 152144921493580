import React, { useState, useEffect, useContext } from 'react';
import "./css/login.css"
import { UserContext } from '../../Utills/UserContext';
import { json, useLocation, useNavigate } from "react-router-dom";
import { Fade, Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css'

const Common = (page) => {
//  console.log('page',page.page);
    const { theme, setTheme } = useContext(UserContext);

    const navigate = useNavigate();

    const slideImages = [
      {
        url: '/images/banner/banner11.webp',
        caption: 'Slide 1',
        content:"Welcome to Islamic Tijarat – A Shariah-compliant, ethical, and transparent trading platform",
        description:"Engage in halal trading with trust and integrity. Connect with reliable traders and grow your business the Islamic way."
      },
      {
        url: '/images/banner/banner22.webp',
        caption: 'Slide 2',
        content: "Create Your Customised Watchlist – Track Your Favourite Halal Stocks!",
        description: "Build a personalised watchlist of Shariah-compliant stocks. Stay updated with real-time prices and make informed investment decisions the ethical way."
    
      

      },
      {
        url: '/images/banner/banner33.webp',
        caption: 'Slide 2',
        content: "Discover Halal IPOs – Invest in Shariah-Compliant New Opportunities.",
       description: "Stay ahead with ethical IPO listings. Explore upcoming Shariah-screened companies and invest in businesses that align with Islamic principles."

      },
      {
        url: '/images/banner/banner4.webp',
        caption: 'Slide 2',
        content: "Discover and Invest in Shariah-Compliant Stocks",
       description: "Filter stocks based on Shariah compliance and ethical business practices."

      },
      // {
      //   url: '/images/banner/banner11.webp',
      //   caption: 'Slide 2',
      //   content:"Islamic Tijarat – A Shariah-compliant, ethical, and transparent trading platform",
      //   description:"Engage in halal trading with trust and integrity. Connect with reliable traders and grow your business the Islamic way."

      // },
    ];
  
  return (
    <>
            <div className='text-overlay bg-white'>
                    {/* <img src="/logonew.png" alt='logo' className='mt-1 logo-image' /> */}
            {/* <img src={"/images/app/itg-logo1.png"} alt="Logo" className='logo2' />  */}
  
            <div className="slide-container1 p-0 m-0" >
          <Slide
            autoplay={true}
            duration={2000}
            indicators={(index) => <div className="custom-indicator1 mt-3"></div>}
            arrows={false} // Hide left & right buttons
            pauseOnHover={false}
          >
            {slideImages.map((slide, index) => (
              <>

<div className='mt-3 p-2'key={index} >
<h3 className=''>
{slide.content}

</h3>
<p className='discription-font px-5 mt-4'>{slide?.description}</p>
</div> 
              <div key={index} className="slide-item1 p-0 mt-3">

                <div
                  className="slide-image1 p-0 m-0"
                  style={{
                    backgroundImage: `url(${slide.url})`,
                  }}
                >
             
                </div>
              </div>
              </>
            ))}
          </Slide>
        </div>
              {/* <img src="/logonew.png" alt='logo' className='mt-1 logo-image' />
              <img src={"/images/app/itg-logo1.png"} alt="Logo" className='logo2' /> */}
              {/* <p className='paratext px-4'>
                At Islamic Tijarat , we redefine what it means to invest with faith. Our industry-leading Shariah screening technology and uncompromising compliance standards make your investment journey seamless and precise, so you never have to choose between profits and principles.
                Pure Profits. Absolute Peace of Mind.
                Invest the Right Way—Shariah Compliant, Always. </p> */}
          {/* <div className='mt-3'>
                <div className={`${theme ? "social-icons-dark" : "social-icons"} mb-4  mt-0`}>
                  <a href="https://www.facebook.com/islamictijarat" target="_blank" rel="noopener noreferrer">
                    <img src='/images/facebook.webp' className='p-2 ' style={{ backgroundColor: "white", borderRadius: '50%' }} width={38} height={38} />
                  </a>
                  <a href="https://www.instagram.com/islamictijarat" target="_blank" rel="noopener noreferrer">
                    <img src='/images/insta.webp' className=' p-2 ' style={{ backgroundColor: "white", borderRadius: '50%' }} width={40} height={40} />
                  </a>
                  <a href="https://x.com/islamictijarat" target="_blank" rel="noopener noreferrer">
                    <img src='/images/twitter.webp' className=' p-2 ' style={{ backgroundColor: "white", borderRadius: '50%' }} width={35} height={35} />
                  </a>
                  <a href="https://www.youtube.com/islamictijarat" target="_blank" rel="noopener noreferrer">
                    <img src='/images/youtube.webp' className='  p-2 ' style={{ backgroundColor: "white", borderRadius: '50%' }} width={35} height={35} />
                  </a>
                  <a href="https://www.linkedin.com/in/islamictijarat" target="_blank" rel="noopener noreferrer">
                    <img src='/images/linkedin.webp' className='p-2' style={{ backgroundColor: "white", borderRadius: '50%' }} width={35} height={35} />
                  </a>
                  <a href="https://whatsapp.com/channel/0029VakHKaFJENy4EzxloA13" target="_blank" rel="noopener noreferrer">
                    <img src='/images/whatsapp.webp' className='p-2' style={{ backgroundColor: "white", borderRadius: '50%' }} width={35} height={35} />
                  </a>
                  <a href="https://t.me/islamictijarat" target="_blank" rel="noopener noreferrer">
                    <img src='/images/islamic/telegram.webp' className='p-2' style={{ backgroundColor: "white", borderRadius: '50%' }} width={35} height={35} />
                  </a>
                </div>
                    {page.page ==  "register" &&
                    <>
                    <p className='paratext mt-3'>Do you have an account</p>
                    <a className='paratext btn-card text-white' onClick={() => { navigate("/login") }} >Log In?</a>
                    </>
                }
               {page.page ==  "login" &&
                <>
                <p className='paratext mt-3'>Don’t have an account</p>
                <a className='paratext btn-card text-white' onClick={() => { navigate("/register") }} >Sign Up?</a>
                </>
                 }
              </div>  */}
            </div>
    </>
    
  )
}

export default Common