import React, { useState, useContext, useEffect } from 'react';
import { PremiumModal, WatchlistModal } from '../pages/Disclaimer/DisclaimerModal';
import { UserContext } from '../Utills/UserContext';
import FetchData from '../fetchapi/Apifetch';
import { ToastContainer, toast } from 'react-toastify';


 const ImageviewContainer = ({ item, Inventrystyle }) => {

    const stock = item;
    const splitStock = (stock) => {
      if (!stock) return '';
      const lettersOnlyRegex = /^[A-Za-z]/;
      const words = stock.split(' ');
      const firstChar = words[0]?.charAt(0).match(lettersOnlyRegex) ? words[0].charAt(0) : '';
      const secondChar = words[1]?.charAt(0).match(lettersOnlyRegex) ? words[1].charAt(0) : '';
      return `${firstChar}${secondChar}`.toUpperCase();
    };

   
   return (
      <div>
        <div style={Inventrystyle} >
          {splitStock(stock)}
        </div>
      </div>
    );
  };

 const Perimumblar = ({ childran,theme, primumfeatureall }) => {
//      const Premiumfeatures = primumfeatureall;
//  console.log('Premiumfeatures',Premiumfeatures);
const [Showmodel, setShowmodel] = useState(false);


const Storedvalue = JSON.parse(localStorage.getItem("email"));
const Premiumfeatures = Storedvalue?.premiumData;

 

const CloseModel = async () => {
  setShowmodel(false)
}

    const handlePremiumModal = () => {
      setShowmodel(true); // Show the modal
    };
    const styleOneHeader = !primumfeatureall ? { 
      position: 'relative', 
      justifyContent: 'center', 
      alignItems: 'center',
      backgroundColor: theme ? '#1B1B1B59' : '#fff',
  }:{

  }
    const styleTwoHeader = !primumfeatureall ? {
      
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backdropFilter: 'blur(25px)',
      zIndex: 1,
    }:{}
    const styleThreeHeader = !primumfeatureall ? {
      position: 'relative', 
      zIndex: 2, 
      top:'50%',
      color: theme ? 'white' : 'black',
      fontSize: '16px', 
      textAlign: 'center',

    }:{
      display:'none'
     
    }
    return (
    <div style={styleOneHeader} >
     <div style={styleTwoHeader} className='text-center'>
    <div className={`pointer text-center fw-bold`} style={styleThreeHeader}  onClick={()=>{handlePremiumModal()}} >
      <i className="fa fa-lock me-2" />
      Upgrade & Unlock Click It 
    </div> 
     </div>
 {childran}
   {Showmodel && <PremiumModal showModal={Showmodel} handleClose={CloseModel} />}  
    </div>
    );
  };


  const Watchlistcommon =  ({ StockID, Watchlistmodel, setWatchlistmodel, Watchlistcheck })  =>   {

    const [Showmodel, setShowmodel] = useState(false);
    const [buttonclickcreate, setbuttonclickcreate] = useState(true);
    const Storedvalue = JSON.parse(localStorage.getItem("email"));
    const profiledetails = Storedvalue?.user;
    const Premiumfeatures = Storedvalue?.premiumData?.Preimum_data ;
    const [watchlist, setwatchlist] = useState(false);
    const { theme, setTheme, setStoredFilter, storedFilters } = useContext(UserContext);
    const [watchlistcategory, setwatchlistcategory] = useState('');
    const [createcategorymodel, setcreatecategorymodel] = useState(false);
    const [categorymodel, setCategorymodel] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [data, setData] = useState();

    useEffect(()=>{
      if(StockID){
        fetchData(StockID);
      }
    },[StockID])


    const [formdata4, setFormdata4] = useState({
      user_id: profiledetails._id,
      title: "",
      status: 1
    });

   const [formdata1, setFormdata1] = useState({
      user_id: profiledetails._id,
      watchlist_cat_id: "",
      stock_symbol: "",
      status: 1,
    });


   


    const CloseModel = async () => {
      setShowmodel(false)
    }
 
    const openCreateCategoryModal = () => {
      setWatchlistmodel(false);
      setcreatecategorymodel(true);
      

    };


    // const handleCheckboxChange11 = (categoryId) => {

    //   setSelectedCategories(prevSelectedCategories => {
    //     if (prevSelectedCategories.includes(categoryId)) {
    //       return prevSelectedCategories.filter(id => id !== categoryId);
    //     } else {
    //       return [...prevSelectedCategories, categoryId];
    //     }
    //   });
    // };

    const [Showmodelalert, setShowmodelalert] = useState(false);

    const CloseModelalert = async () => {
      setShowmodelalert(false)
    }
    const handleCheckboxChange11 = (category) => {
 
      var categoryId = category._id;
      setSelectedCategories(prevSelectedCategories => {
        if (prevSelectedCategories.includes(categoryId)) {
          return prevSelectedCategories.filter(id => id !== categoryId);
        } else {
          if (category.stock_count >= Premiumfeatures?.watchlist ) {
  
            if(Premiumfeatures?.watchlist == 20){
              setShowmodel(true);
            } else if(Premiumfeatures?.watchlist == 50){
              setShowmodelalert(true)
            }
            return prevSelectedCategories;
          }else{
          return [...prevSelectedCategories, categoryId];
          }
        }
      });
    };
  

  const handleSubmit = async () => {

    setWatchlistmodel(false)
    const updatedFormData = {
      ...formdata1,
      watchlist_cat_id: selectedCategories,
      stock_symbol: StockID,
    };
    setFormdata1(updatedFormData);
    const dataObject = {
      ...updatedFormData,
    };
    try {
        const res = await FetchData("addmultiplewatchlist", 'POST', JSON.stringify(dataObject), true, false);
        if (res.success) {
          toast.success("Watchlist Updated");
          Watchlistcheck();
           fetchData();
        } else {
          toast.error(res.error);
        }
      
    } catch (error) {
      toast.error("Watchlist Already Entered");
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (watchlistcategory) {
      setwatchlist(false)
      const initialSelected = watchlistcategory
        .filter(category => category.stock_exists)
        .map(category => category._id);
      // console.log('initialSelected', initialSelected)
      setSelectedCategories(initialSelected);
      const hasStock = watchlistcategory.some(category => category.stock_exists);
      if (hasStock) {
        setwatchlist(true);

      } else {
        setwatchlist(false);

      }
    }
  }, [watchlistcategory]);

  const Handlewatchlistcategory = async () => {
    try {
      if (watchlistcategory.length == (Premiumfeatures?.watchlist == 50 ? 6 : 1)) {
        setShowmodel(true);
        setFormdata4(prevState => ({
          ...prevState,
          title: ""
        }));
        setcreatecategorymodel(false);
        return;
      }
      setcreatecategorymodel(false);
      const res = await FetchData("addwatchlistcat", 'POST', JSON.stringify(formdata4), true, false);
      if (res.success) {
        setFormdata4(prevState => ({
          ...prevState,
          title: ""
        }));
        setWatchlistmodel(true);
        fetchDatawatchlist();
        toast.success("Watchlist category created");
      } else {
        setFormdata4(prevState => ({
          ...prevState,
          title: ""
        }));
        toast.error("Watchlist category  already exist");
      }
      return null;
    } catch (error) {
      toast.error("Watchlist category already exist");
      setFormdata4(prevState => ({
        ...prevState,
        title: ""
      }));
      console.error('Error:', error);
      return null;
    }
  }




   const fetchData = async (stockId) => {
    try {
      const res = await FetchData(
        `watchlists/${profiledetails._id}/${stockId ? stockId : StockID}`,
        "GET",
        null,
        true,
        false
      );

      if (res && res.data) {
        await setwatchlistcategory(res.data);
        setCategorymodel(true);
      } else {
        console.error("No data returned from the API");
        setCategorymodel(true);
      }
    } catch (error) {
      // Log the error details for debugging
      console.error("Error occurred while fetching data:", error);
      // You can also set an error state or message to display to the user
      setCategorymodel(true); // Optionally, if you want to handle the error differently in UI
    }
  };


  const fetchDatawatchlist = async () => {
    try {
      const res = await FetchData(`watchlistcat/${profiledetails._id}`, "GET", null, true, false);

      if (res && res.data) {

        setwatchlistcategory(res.data); // Populate watchlist

      } else {
        throw new Error("No data received from the API");
      }
    } catch (error) {
      // Handle any error (e.g., 400 status or network failure)
      console.error('Error fetching data:', error);
    }
  };





  useEffect(() => {
    if (formdata4?.title.length > 0) {
      setbuttonclickcreate(false); // Enable button when both fields have input
    } else {
      setbuttonclickcreate(true); // Disable button if any field is empty
    }
  }, [formdata4?.title]);
    const handleInputChangevalue = (e) => {
    const { name, value } = e.target;
    setFormdata4({ ...formdata4, [name]: value });
  };


    return (

    <>
   <ToastContainer />
   {Showmodelalert && <WatchlistModal showModal={Showmodelalert} handleClose={CloseModelalert}  theme={theme}/>}

          <div
                  className={`modal fade ${Watchlistmodel ? "show" : ''}`}
                  id="exampleModal2"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                  style={{
                    display: Watchlistmodel ? "block" : 'none',
                    backgroundColor: Watchlistmodel ? 'rgba(0, 0, 0, 0.5)' : '',
                    transition: 'opacity 0.3s ease'
                  }}
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0 rounded-lg shadow-lg">
                      <div
                        className="modal-header text-center "
                        style={{
                          background: theme ? "#7E66BC" : "#5b298c",
                          borderBottom: "none",
                          borderRadius: "0.5rem 0.5rem 0 0",
                          padding: "1rem 1.5rem",
                        }}
                      >
                        <h5 className="modal-title w-100 " id="exampleModalLabel" style={{ color: '#fff', }}>
                          Add to Your Watchlist
                        </h5>
                        <button
                          type="button"
                          className="btn-close btn-close-white text-white"
                          onClick={() => setWatchlistmodel(false)}
                          aria-label="Close"
                        ></button>
                      </div>
                      <div
                        className="modal-body"
                        style={{
                          padding: "1.5rem 4rem",
                          backgroundColor: "#F1F1F1",
                        }}
                      >
                        <div className="mb-4">
                          <div className="d-flex justify-content-end align-items-center mb-3">
                            <label className={`form-label mb-0 ${theme ? "text-dark" : ''} me-2`} style={{ fontSize: '14px' }}>Create Watchlist:</label>
                            <button
                              type="button"
                              className="btn btn-sm "
                              onClick={openCreateCategoryModal}
                              style={{
                                background: theme ? "#7E66BC" : "#5b298c",
                                color: '#fff',
                                borderRadius: '0.3rem',
                                padding: '0.5rem 1rem'
                              }}
                            >
                              + Create
                            </button>
                          </div>
                          <div className="d-flex flex-column gap-2" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            {watchlistcategory && watchlistcategory.map((category) => (
                              <div
                                key={category._id}
                                className="d-flex align-items-center p-2 rounded shadow-sm"
                                style={{ border: theme ? '1px solid #7E66BC' : '1px solid #5b298c', backgroundColor: '#f0edf7' }}
                              >
                                <input
                                  type="checkbox"
                                  className="form-check-input me-2"
                                  style={{ width: '20px', height: '20px', backgroundColor: selectedCategories.includes(category._id) ? "#5b298c" : "#fff", border: "0.5px solid #5b298c" }}
                                  id={`category-${category._id}`}
                                  checked={selectedCategories.includes(category._id)}
                                  onChange={() => handleCheckboxChange11(category)}
                                />
                                <div>
                                  <label htmlFor={`category-${category._id}`} className="form-check-label" style={{ fontSize: '12px' }}>
                                    {category.title}
                                  </label>
                                  <p className="mb-0 text-muted" style={{ fontSize: '12px' }}>
                                    {category.stock_count} Stocks
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer border-0" style={{ backgroundColor: '#f1f1f1' }}>
                        <div className="d-flex justify-content-center gap-3 w-100">
                          {/* <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={() => setCategorymodel(false)}
                            style={{
                              borderRadius: "0.3rem",
                              padding: '0.5rem 1.5rem',
                              borderColor: '#ccc',
                              color: '#333',
                              transition: "background 0.3s, border-color 0.3s",
                            }}
                            onMouseEnter={(e) => e.target.style.borderColor = '#999'}
                            onMouseLeave={(e) => e.target.style.borderColor = '#ccc'}
                          >
                            Cancel
                          </button> */}
                          <button
                            onClick={handleSubmit}
                            className="btn btn-primary "
                            // disabled={buttonclick}
                            style={{
                              backgroundColor: theme ? "#7E66BC" : "#5b298c",
                              border: "none",
                              color: "#fff",
                              borderRadius: "0.3rem",
                              padding: '0.5rem 1.5rem',
                              transition: "background 0.3s, transform 0.2s",
                            }}
                            onMouseEnter={(e) => e.target.style.backgroundColor = theme ? "#7E66BC" : "#5b298c"}
                            onMouseLeave={(e) => e.target.style.backgroundColor = 'rgb(126, 102, 188)'}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`modal fade ${createcategorymodel ? "show" : ''}`} id="createCategoryModal" tabIndex="-1" aria-labelledby="createCategoryModalLabel" aria-hidden="true"
                  style={{ display: createcategorymodel ? "block" : 'none', backgroundColor: createcategorymodel ? '#0009' : '' }}>
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div
                        className="modal-header"
                        style={{
                          backgroundColor: "rgb(126, 102, 188)",
                          color: "#fff",
                          borderBottom: "none",
                          borderRadius: "0.5rem 0.5rem 0 0",
                          padding: "1rem 1.5rem",
                        }}
                      >
                        <h5 className="modal-title" id="createCategoryModalLabel">Create Watchlist</h5>
                        <button type="button" className="btn-close btn-close-white" onClick={() => { setcreatecategorymodel(false) }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body" style={{ padding: "1.5rem 2rem", backgroundColor: "#F1F1F1" }}>
                        <div className="mb-4">
                          <label htmlFor="categoryTitle" className="form-label fw-bold mb-3">Watchlist Title:</label>
                          <input type="text" className="form-control" value={formdata4.title} name="title" id="categoryTitle" onChange={(e) => { handleInputChangevalue(e) }} placeholder="Enter Watchlist title" />
                        </div>
                      </div>
                      <div className="modal-footer" style={{ borderTop: "none", padding: "1rem 1.5rem" }}>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => { setcreatecategorymodel(false) }}
                          style={{ borderRadius: "0.3rem", padding: '0.5rem 1.5rem' }}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                          disabled={buttonclickcreate}
                          style={{
                            backgroundColor: "rgb(126, 102, 188)",
                            border: "none",
                            color: "#fff",
                            borderRadius: "0.3rem",
                            padding: '0.5rem 1.5rem',
                            transition: "background 0.3s, transform 0.2s",
                          }}
                          onClick={() => { Handlewatchlistcategory() }}  // Function to handle category creation
                          onMouseEnter={(e) => e.target.style.backgroundColor = 'rgb(154, 123, 187)'}
                          onMouseLeave={(e) => e.target.style.backgroundColor = 'rgb(126, 102, 188)'}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </div>
                  {Showmodel && <PremiumModal showModal={Showmodel} handleClose={CloseModel} />}  

                </div></>
    );
  }


const StatusCommon = ({ itemsvalue }) => {

    const statusImages = {
      1: "/images/checklist.webp",
      2: "/images/non-permisable.webp",
      3: "/images/underreview.webp",
      4: "/images/non-permisable.webp",
      6: "/images/non-permisable.webp",
      7: "/images/non-permisable.webp",
    };
      const imageSrc = statusImages[itemsvalue?.status] || "/images/underreview.webp";
  
    return (
      <td className="heder-title">
        <span className="ms-1">
          <img src={imageSrc} className="status-Img" alt="status" />
        </span>
      </td>
    );
  };


  
  





  
  

  export { ImageviewContainer, Perimumblar, Watchlistcommon, StatusCommon };

